import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Heading from 'components/Heading'

import { generalSection, generalHeading, generalBody, generalBodyText, generalBodyImg, generalBodySection } from './general.module.css'

function General() {

    const data = useStaticQuery(graphql`
        query GeneralQuery {
            allShopifyArticle {
                edges {
                    node {
                        title
                        excerpt
                        image {
                            src
                            altText
                        }
                    }
                }
            }
            allShopifyBlog {
                nodes {
                    title
                    internal {
                        content
                    }
                }
            }
        }
      `)

    const { allShopifyArticle, allShopifyBlog } = data
    const generalHeadingData = allShopifyBlog.nodes[0]
    const generalContentData = allShopifyArticle.edges

    let generalContentJSX = generalContentData.map(({ node }) => {
        return (
            <div className={generalBodySection} key={ node.title }>
                <div className={generalBodyText}>
                    <div>
                        <Heading level="h3">{node.title}</Heading>
                        <p>{node.excerpt}</p>
                    </div>
                </div>
                
                <div className={generalBodyImg}>
                    <img  
                        src={node.image.src}
                        alt={node.image.altText}
                    />
                </div>
            </div>
        );
    });

    return (
        <section className={generalSection}>
            <div className={generalHeading}>
                <div>
                    <div>
                        <Heading level="h2">{generalHeadingData.title}</Heading>
                    </div>
                    <div>
                        <p>{generalHeadingData.internal.content}</p>
                    </div>
                </div>
            </div>

            <div className={generalBody}>
                {generalContentJSX}
            </div>
        </section>
    )
}

export default General