import * as React from "react"
import Header from "components/Header"
import Footer from "components/Footer"
import General from "components/General"


const GeneralPage = () => {
  return (
    <main>
        <Header />
        <General />
        <Footer />
    </main>
  )
}

export default GeneralPage
